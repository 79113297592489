import React from "react"
import { graphql } from "gatsby"
import Seo from '../components/seo';
import Layout from '../containers/layout/layout'
import Banner from '../containers/projects/banner'
import ProjectP from '../containers/projects/project'

const Project = ({ data }) => {
    const url = data.site.siteMetadata.siteUrl;
    const description = `Découvrez les réalisations de piscines et jardins que nous avons réalisé dans le Var et ses alentours.`;

    return(
        <Layout url={url}>
            <Seo title="Vos projets" description={description}/>
            <Banner/>
            <ProjectP/>
        </Layout>
    )
}
export default Project;


export const query = graphql`
query PageProjetQuery {
  site {
    siteMetadata {
      siteUrl
    }
  }
}
`
